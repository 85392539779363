.account-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-info .user-info-line {
     width: 100%;
     font-size: 16px;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
}

.user-info .user-info-title {
     margin-bottom: 0;
 }

.user-info .user-info-content {
     margin-left: 40px;
     margin-bottom: 0;
 }

.user-info .user-info-divider {
     width: 95%;
     height: 1px;
     margin-top: 10px;
     margin-bottom: 10px;
     background-color: #010101;
     opacity: 0.3;
 }

.user-info .user-info-generate-token {
     width: 100%;
     margin-top: 10px;
 }


.add-credits-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add-credits-container .add-credits-buttons {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.add-credits-container .add-credits-buttons .add-credits-button {
    margin-top: 10px;
    margin-bottom: 10px;
}
