.page-layout {
    min-height: 100vh;
}
.page-layout .layout-content {
    margin-left: 200px;
    padding: 20px;
    transition: margin-left 0.2s;
}

.page-layout .layout-content-extended {
    margin-left: 80px;
    transition: margin-left 0.2s;
}

.page-layout .content {
    padding-top: 80px;
}

.page-layout .activation-alert {
    margin: 0 10px 15px 10px;
}

.page-layout .header {
    position: fixed;
    z-index: 1;
    width: 100%;
}

.page-layout .header .header-logo {
     height: 35px;
     width: 100px;
 }


.page-layout .menu-header {
    line-height: 64px;
    float: right;
}

.page-layout .menu-sider {
    padding-top: 60px;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}