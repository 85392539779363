.checkout .title {
    margin-bottom: 10px;
}

.checkout .card-element-container {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.checkout .card-number {
    margin-bottom: 10px;
}

.checkout .bottom-card-elements {
    display: flex;
    flex-direction: row;
}
.checkout .bottom-card-elements .card-expiry {
    margin-right: 5px;
    flex: 0.65;
}

.checkout .bottom-card-elements .card-cvc {
    margin-left: 5px;
    flex: 0.35;
}

.checkout .checkout-button {
    width: 100%;
    margin-top: 10px;
}
