.service-doc {
    padding: 15px;
    font-size: 14px;
}
.service-doc-desc-container {
    margin-left: 10px;
}
.service-doc-route {
    color: #e83e8c;
}
