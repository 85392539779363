@import '~antd/dist/antd.css';

body {
    font-family: "Source Sans Pro", Arial, sans-serif
}

h1, h2, h3, h4, h5, h6, .ant-menu-item, .ant-table-column-title, .ant-btn {
    font-family: "lemonMilk", Arial, sans-serif !important;
    font-weight: bold !important;
}

@font-face {
    font-family: "lemonMilk";
    src: url("./fonts/lemon_milk/LemonMilk.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "lemonMilk";
    src: url("./fonts/lemon_milk/LemonMilkbold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
}