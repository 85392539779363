.sign-in-container {
    height: 100vh;
    background-color: #757EFF;
    background-image: url("../assets/svg-layer-2.svg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo {
    width: 150px;
    height: 150px;
    margin-bottom: 30px;
}

.alerts-container {
    margin-bottom: 30px;
}

.sign-in-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.divider {
    height: 450px;
    width: 1px;
    background-color: #FFFFFF;
}

.sign-in-card {
    width: 300px;
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.sign-in-card-button {
    width: 100%;
}

.links-container {
    margin-top: 30px !important;
}

.link {
    color: #FFFFFF;
    font-family: "lemonMilk", Arial, sans-serif !important;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px !important;
}
